import React, { useState, useEffect } from 'react';
import './OrderBatchPage.css';
import {
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Flex,
  Title,
} from "@tremor/react";
import SidebarComponent from '../components/SidebarComponent';
import TitleComponent from '../components/TitleComponent';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Batch } from '../interfaces/BatchInterfaces';
import { TrashIcon } from '@heroicons/react/24/outline';


export default function OrderBatchPage(
        {setSelectedOrders}:{setSelectedOrders:Function}
    ) {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;

    const [requested, setRequested] = useState<boolean>(false);
    const [batches, setBatches] = useState<Batch[]>([]);
    const [page, setPage] = useState<number>(0);
    const [maxPage, setMaxPage] = useState<number>(0);
    const [fileDownloadLoading, setFileDownloadLoading] = useState<string>("");

    const baseURL = process.env.REACT_APP_MAZELOG_SYSTEM_BASE_URL;

    const handleSubmit = () => {
        let URL = `${baseURL}/batches?page=${page}`;
        axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
        .then((res)=>{
            console.log(res.data.results);
            setBatches(res.data.results);
            setPage(res.data.pages.current);
            setMaxPage(res.data.pages.max);
        })
        .catch((err)=>{
          console.log(err);
        })
      }

    useEffect(()=>{
    if (!requested){
        handleSubmit();
        setRequested(true);
    }
    },[requested])

    const pageForward = () => {
      setPage(page + 1);
      setRequested(false);
    }

    const pageBack = () => {
      setPage(page - 1);
      setRequested(false);
    }

    const convertDate = (date:Date) : string =>  {
        let newDate = new Date(date);
        newDate.setHours(newDate.getHours() - 3);
        return newDate.toLocaleString('pt-BR').substring(0,10);
    }

    const navigate = useNavigate();
    const openStickers = (batch_id: string) => {
        let URL = `${baseURL}/batches/${batch_id}/succeeded`;
        axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
        .then((res)=>{
            console.log(res.data);
            setSelectedOrders(res.data.succeeded);
            navigate("/orders")
        })
        .catch((err)=>{
          console.log(err);
        })
    }

    const buildOrderCsv = async (batch_id: string) : Promise<string> =>  {
        let URL = `${baseURL}/batches/${batch_id}/failed`;
        const resp = await axios.get(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
        return resp.data
      }
    
      const downloadCsv = async (batch_id: string) => {
        setFileDownloadLoading(batch_id);
        const csvData = await buildOrderCsv(batch_id);
        const a = document.createElement("a");
        a.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData));
        a.setAttribute("download", `error_report_${batch_id}.csv`);
        a.click();
        setFileDownloadLoading("");
      }

    const deleteBatch = (batch_id: string) => {
        if (window.confirm("Deseja mesmo apagar esse pedido?")){
            let URL = `${baseURL}/batches/${batch_id}`;
            axios.delete(URL,{headers: {accept: 'application/json', "Authorization": oauth}})
            .then((res)=>{
                alert("Lote apagado com sucesso!")
            })
            .catch((err)=>{
            console.log(err);
            alert("Erro!")
            })
        }
    }

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="OpsViews">
          <Card>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>ID lote</TableHeaderCell>
                        <TableHeaderCell className="text-left">Data criação</TableHeaderCell>
                        <TableHeaderCell className="text-left">Quantidade</TableHeaderCell>
                        <TableHeaderCell className="text-left">Modal</TableHeaderCell>
                        <TableHeaderCell className="text-left">
                          <Flex flexDirection='row' className='max-w-md' justifyContent='evenly' alignItems='center'>
                            <Button
                              className='OpenOrderBtn'
                              disabled={page === 0}
                              onClick={pageBack}
                            >
                              {"<"}
                            </Button>
                            <Title>
                              Página {page+1} de {maxPage+1}
                            </Title>
                            <Button
                              className='OpenOrderBtn'
                              disabled={page === maxPage}
                              onClick={pageForward}
                            >
                              {">"}
                            </Button>
                          </Flex>
                        </TableHeaderCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    { batches
                        .map((batch) => (
                            <TableRow key={ batch.id }>
                                <TableCell className="TableField">{ batch.id }</TableCell>
                                <TableCell className="TableField">{ convertDate(batch.created_at) }</TableCell>
                                <TableCell className="TableField">{ batch.len_succeeded }</TableCell>
                                <TableCell className="TableField">{ batch.modal }</TableCell>
                                <Button
                                    className='BatchBtn'
                                    onClick={() => openStickers(batch.id)}
                                >Baixar etiquetas</Button>
                                <Button
                                    className='BatchBtn'
                                    loading={fileDownloadLoading === batch.id}
                                    onClick={() => downloadCsv(batch.id)}
                                >Relatório de erros</Button>
                                <Button
                                    className='OpenOrderBtn'
                                    icon={TrashIcon}
                                    loading={false}
                                    onClick={() => deleteBatch(batch.id)}
                                />
                            </TableRow>
                        )) }
                </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    </main>
  );
}