import React, { useState } from 'react';
import './CreateOrganizationsPage.css';
import { 
    Button,
    Text,
    Grid,
    Col,
    Title,
    TextInput,
    Dropdown,
    DropdownItem,
    Card,
    Toggle,
    ToggleItem 
} from "@tremor/react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { MeliIntegrationData } from '../../interfaces/AccessInterfaces';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from "../../components/TitleComponent";

export default function CreateMercadoLivreIntegrationPage() {
    const [cookies] = useCookies(['mazelog-user']);
    const token = cookies['mazelog-user']?.token_data?.access_token;
    const oauth = "Bearer " + token;

    const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;

    const baseIntegration = {
        id_org: null,
        seller_id: null
    }

    const [requestProcessing, setRequestProcessing] = useState(false);
    const [meliIntegrationData, setMeliIntegrationData] = useState<MeliIntegrationData>(baseIntegration);

    const navigate = useNavigate();
    const createOrg = () => {
        return axios.post(`${baseURL}/mercado_livre/setup`,
            meliIntegrationData,
            {headers: {
                "Content-Type": "application/json",
                "Authorization": oauth,
            }});
    }

    const handleSubmit = async () => {
        setRequestProcessing(true);
        createOrg()
        .then((res) => {
            alert("Configuração inicial realizada com sucesso! Enviar para cliente o seguinte link: https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=4303884192768014&redirect_uri=https://mazelog-auth-wgtrqaqgyq-uc.a.run.app/mercado_livre/setup");
            navigate('/access')
        })
        .catch((err) => {
            alert('Erro!');
            setRequestProcessing(false);
        })
      }

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="CreateUserViews">
            <Card>
                <Title>Criar integração organização com o Mercado Livre</Title>
                <Grid numCols={2} className="gap-2">
                    <Col numColSpan={1}>
                        <Text>ID Organizacao</Text>
                        <TextInput 
                        className='max-w-md'
                        placeholder='0'
                        onChange={(e) => {
                            setMeliIntegrationData({
                                ...meliIntegrationData,
                                id_org: Number(e.target.value)
                            })
                        }}
                        />
                    </Col>
                    <Col numColSpan={1}>
                        <Text>Seller ID</Text>
                        <TextInput 
                        className='max-w-md'
                        placeholder='000000'
                        onChange={(e) => {setMeliIntegrationData({
                            ...meliIntegrationData,
                            seller_id: Number(e.target.value)
                        })}}
                        />
                    </Col>
                </Grid>
            <Button
            disabled={requestProcessing}
            onClick={handleSubmit}
            className='CreateUserBtn'
            > Criar </Button>
            </Card>
        </div>
      </div>
    </main>
  );
}