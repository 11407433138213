import React from 'react';
import './SidebarComponent.css';
import { Link, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import {Squares2X2Icon,
        MagnifyingGlassIcon,
        ArrowsRightLeftIcon,
        ArrowLeftOnRectangleIcon,
        UserIcon,
        ExclamationTriangleIcon,
        TableCellsIcon, } from '@heroicons/react/24/outline'

export default function SidebarComponent() {
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['mazelog-user']);

  function logout() {
    removeCookie("mazelog-user", {path: "/"});
  }

  return (
    <div className='Sidebar'>
      <Link to="/">
        <h1 className='SidebarTitle'>MAZELOG</h1>
      </Link>
      <div className='PagesList'>
        <Link to="/" className={location.pathname==='/'  ? 'SelectedPageButton' : 'PageButton'}>
          <Squares2X2Icon className='SidebarIcon'/> Dashboard
        </Link>
        <Link to='/search' className={location.pathname==='/search'  ? 'SelectedPageButton' : 'PageButton'}>
          <MagnifyingGlassIcon className='SidebarIcon'/>Pesquisa
        </Link>
        <Link to='/batches' className={location.pathname==='/batches'  ? 'SelectedPageButton' : 'PageButton'}>
          <TableCellsIcon className='SidebarIcon'/>Etiquetas
        </Link>
        <Link to='/operations' className={location.pathname==='/operations'  ? 'SelectedPageButton' : 'PageButton'}>
          <ArrowsRightLeftIcon className='SidebarIcon'/>Operação
        </Link>
        <Link to='/access' className={location.pathname==='/access'  ? 'SelectedPageButton' : 'PageButton'}>
          <UserIcon className='SidebarIcon'/>Acessos
        </Link>
        <Link to='/occurrence' className={location.pathname==='/occurrence'  ? 'SelectedPageButton' : 'PageButton'}>
          <ExclamationTriangleIcon className='SidebarIcon'/>Ocorrências
        </Link>
      </div>
      <button className='PageButton' onClick={() => logout()}>
        <ArrowLeftOnRectangleIcon className='SidebarIcon'/>Logout
      </button>
    </div>
  );
};